import { useStaticQuery, graphql } from 'gatsby';

const releaseChoice = (data: Array<any> = [], count = 3, skip = 0) => {
  const ary = data.filter((item: any) => {
    const hotelid = item.node.hotel.map((hotel: any) => {
      return hotel.id
    })
    return hotelid.includes('srph')
  })
  return ary.slice(skip, count == 0 ? undefined : count)
}

export default releaseChoice